export function sortableUpdatesWidget() {
  const widgets = document.querySelectorAll(".field--sortableupdates")
  for (const widget of widgets) {
    const items = Array.from(widget.querySelectorAll(".sortable-list-item"))
    widget.addEventListener("click", (e) => {
      e.preventDefault()
      if (e.target?.closest(".move-up"))
        moveElement(
          items,
          items.indexOf(e.target?.closest(".sortable-list-item")),
          -1,
        )
      if (e.target?.closest(".move-down"))
        moveElement(
          items,
          items.indexOf(e.target?.closest(".sortable-list-item")),
          1,
        )
      if (e.target?.closest(".highlight-label"))
        radioCheck(items, e.target.closest(".sortable-list-item"))
    })
  }
}

function radioCheck(items, changeItem) {
  const state = changeItem.querySelector('[type="checkbox"]').checked
  for (const item of items) {
    const checkbox = item.querySelector('[type="checkbox"]')
    checkbox.checked = false
  }
  changeItem.querySelector('[type="checkbox"]').checked = !state
}

function resetOrdering(els) {
  for (const [idx, el] of els.entries()) {
    el.style.order = idx
    const orderingInput = el.querySelector('[name="update-ordering"]')
    orderingInput.value = orderingInput.value.replace(/:.*/, `:${idx}`)
  }
}

function moveElement(els, idx, relPos) {
  const newPos = idx + relPos
  if (newPos < 0 || newPos > els.length - 1) return

  const el = els[idx]
  els.splice(idx, 1)
  els.splice(newPos, 0, el)
  resetOrdering(els)
}
